export default function dayFormatterStructuredData(eng) {
  switch (eng) {
    case 1:
    case "mon":
      return "Monday"
    case 2:
    case "tue":
      return "Tuesday"
    case 3:
    case "wed":
      return "Wednesday"
    case 4:
    case "thu":
      return "Thursday"
    case 5:
    case "fri":
      return "Friday"
    case 6:
    case "sat":
      return "Saturday"
    case 0:
    case "sun":
      return "Sunday"
    case "hol":
      return "Holiday"
    default:
      return "Other"
  }
}
