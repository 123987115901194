export default function dateTimeFormatter(lastChanged) {
  lastChanged = new Date(lastChanged)

  const today = new Date()

  const day =
    lastChanged.getDay() === today.getDay() &&
    lastChanged.getMonth() === today.getMonth() &&
    lastChanged.getFullYear() === today.getFullYear()
      ? "heute"
      : lastChanged.toLocaleDateString("de-DE", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        })

  const time = lastChanged.toLocaleTimeString("de-DE", {
    hour: "2-digit",
    minute: "2-digit",
  })

  return `${day}, ${time}`
}
