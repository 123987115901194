import React, { useState, useEffect } from "react"
import useTheme from "@material-ui/core/styles/useTheme"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import Layout from "../Layout"
import Meta from "../components/Meta"
import styled from "styled-components"
import Typography from "@material-ui/core/Typography"
import Table from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TableRow from "@material-ui/core/TableRow"
import currencyFormatter from "../helper/currencyFormatter"
import DriveEtaIcon from "@material-ui/icons/DriveEta"
import RoomIcon from "@material-ui/icons/Room"
import ScheduleIcon from "@material-ui/icons/Schedule"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import ListItemIcon from "@material-ui/core/ListItemIcon"
import Box from "@material-ui/core/Box"
import Grid from "@material-ui/core/Grid"
import ListItemText from "@material-ui/core/ListItemText"
import Collapse from "@material-ui/core/Collapse"
import ExpandLess from "@material-ui/icons/ExpandLess"
import ExpandMore from "@material-ui/icons/ExpandMore"
import dayFormatterUserInterface from "../helper/dayFormatterUserInterface"
import Header from "../components/Header"
import Footer from "../components/Footer"
import distance from "../helper/distance"
import useGeolocation from "react-hook-geolocation"
import TrendingDownIcon from "@material-ui/icons/TrendingDown"
import TrendingUpIcon from "@material-ui/icons/TrendingUp"
import Counties from "../components/Counties"
import Cities from "../components/Cities"
import Spacer from "../components/Spacer"
import useFetch from "use-http"
import env from "../../env-config.json"
import dateTimeFormatter from "../helper/dateTimeFormatter"
import dayFormatterStructuredData from "../helper/dayFormatterStructuredData"
import Main from "../components/Main"
import LocationMap from "../components/LocationMap"
import { AdsenseAd } from "../components/Adsense"

const MapWrap = styled.div`
  width: 100%;
  height: 300px;
`

const StyledTypographyH1 = styled(Typography)`
  margin: 0rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  ${props => props.theme.breakpoints.up("md")} {
    margin-bottom: 3rem;
    margin-top: 4.5rem;
  }
`

const NeutralGrid = styled(Grid)`
  color: ${props => props.theme.palette.primary.light};

  border-left: 1rem solid ${props => props.theme.palette.secondary.dark};
  border-right: 1rem solid ${props => props.theme.palette.secondary.dark};
`
const FocusGrid = styled(NeutralGrid)`
  background: ${props => props.theme.palette.secondary.main};
  color: ${props => props.theme.palette.primary.light};

  border-left: 1rem solid ${props => props.theme.palette.secondary.dark};
  border-right: 1rem solid ${props => props.theme.palette.secondary.dark};

  &:nth-child(1) {
    border-top: 1rem solid ${props => props.theme.palette.secondary.dark};
    border-bottom: 1rem solid ${props => props.theme.palette.secondary.dark};
  }
  &:nth-child(2) {
    border-top: none;
    border-bottom: 1rem solid ${props => props.theme.palette.secondary.dark};
  }
  &:nth-child(3) {
    border-top: 1rem solid ${props => props.theme.palette.secondary.dark};
    border-bottom: 1rem solid ${props => props.theme.palette.secondary.dark};
  }
  ${props => props.theme.breakpoints.up("md")} {
    /* Öffnungszeiten without borders in 3 column layout */
    &:nth-child(1) {
      border-left: none;
      border-right: none;
    }
    &:nth-child(2) {
      border-top: 1rem solid ${props => props.theme.palette.secondary.dark};
      border-bottom: 1rem solid ${props => props.theme.palette.secondary.dark};
    }
    &:nth-child(3) {
      border-top: 1rem solid ${props => props.theme.palette.secondary.dark};
      border-bottom: 1rem solid ${props => props.theme.palette.secondary.dark};
    }
  }
`

const StyledList = styled(List)`
  color: ${props => props.theme.palette.primary.light};
  * {
    /* disabled ListItem on desktop */
    opacity: 1 !important;
  }
`
const StyledClickableList = styled(StyledList)`
  cursor: pointer;

  &:hover,
  &:active,
  &:focus {
    background: ${props => props.theme.palette.primary.main};
  }
`

const StyledA = styled.a`
  text-decoration: none;
`

const StyledListItemIcon = styled(ListItemIcon)`
  align-self: start;
  text-align: center;
  display: block;
  color: ${props => props.theme.palette.primary.light};
  margin-top: 0.25rem;
`

const StyledTypographyPriceHeadline = styled(Typography)`
  background: ${props => props.theme.palette.secondary.dark};
  color: ${props => props.theme.palette.secondary.contrastText};
  padding-bottom: 0.25rem;
`

const StyledTableHeaderCell = styled(TableCell)`
  border: none;
  color: ${props => props.theme.palette.primary.main};
  background: ${props => props.theme.palette.secondary.dark};
  font-weight: 700;
  text-transform: uppercase;
  padding: 0.5rem !important;
`

const StyledTableBody = styled(TableBody)``
const StyledTableBodyCell = styled(TableCell)`
  color: ${props => props.theme.palette.primary.light};
  border: none;

  svg {
    vertical-align: bottom;
  }
`

const StyledTableContainerPrices = styled(TableContainer)`
  // margin-bottom: 1rem;
`

const StyledDayOfWeek = styled.span`
  display: inline-block;
  width: 80px;
`

const StyledTypographyLastUpdated = styled(Typography)`
  background: ${props => props.theme.palette.secondary.dark};
  color: ${props => props.theme.palette.secondary.contrastText};
  text-transform: uppercase;
`
export default function IdPage({ pageContext: props, path }) {
  const {
    identifier,
    counties,
    cities,
    brand,
    street,
    postalcode,
    county,
    city,
    openingtimes,
    coords,
  } = props

  const url = `${env.siteMetadata.baseUrl}${path}`

  const geolocation = useGeolocation()

  const { get, response, loading, error } = useFetch(env.api.prices)

  const [menuOpenState, setMenuOpenState] = useState(false)
  const [fuels, setFuels] = useState(props?.fuels)

  async function loadOneStation() {
    const fetchedStation = await get(`/get/${identifier}/gzip`)

    if (response.ok && fetchedStation) {
      setFuels(fetchedStation.fuels)
    }
  }

  useEffect(() => {
    loadOneStation()
  }, [])

  const toggleMenu = () => {
    setMenuOpenState(!menuOpenState)
  }

  const theme = useTheme()
  const breakpointSmUp = useMediaQuery(theme.breakpoints.up("sm"))
  const menuOpen = menuOpenState || breakpointSmUp
  const claim = `Deine Tankstelle ${brand}, ${street}, ${postalcode} ${city}`
  const description = `Die aktuellen Benzinpreise deiner Tankstelle ${brand} in der ${street} in ${postalcode} ${city}`
  const address = `${street}, ${postalcode} ${city}`
  const renderWithOpeningtimes = openingtimes.length > 0

  useEffect(() => {
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
    ;(window.adsbygoogle = window.adsbygoogle || []).push({})
  }, [])

  return (
    <Layout>
      <Meta title={claim} description={description} />
      <Header station={props} />

      <Main>
        <MapWrap>
          <LocationMap featured={props} />
        </MapWrap>

        <StyledTypographyH1 align="center" variant="h1">
          {claim}
        </StyledTypographyH1>
        <AdsenseAd>
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-format="fluid"
            data-ad-layout-key="-ed+5p-2-bb+pw"
            data-ad-client="ca-pub-9201149966528047"
            data-ad-slot="8401068078"
            data-adtest={process.env.NODE_ENV === "development" ? "on" : "off"}
          ></ins>
        </AdsenseAd>
        <Grid container spacing={0} justify="flex-start">
          {renderWithOpeningtimes && (
            <Box clone order={{ xs: 1, md: 2 }}>
              <FocusGrid item xs={12} md={4}>
                <StyledList>
                  <ListItem
                    button
                    disabled={breakpointSmUp}
                    onClick={toggleMenu}
                  >
                    <StyledListItemIcon>
                      <ScheduleIcon />
                    </StyledListItemIcon>
                    <ListItemText primary="Öffnungszeiten" />
                    {menuOpen ? <ExpandLess /> : <ExpandMore />}
                  </ListItem>
                  <Collapse in={menuOpen} timeout="auto" unmountOnExit>
                    <StyledList component="div" disablePadding dense>
                      {openingtimes.map(openingTime => {
                        return openingTime.days.map(day => (
                          <ListItem key={`${identifier}${day}`}>
                            <StyledListItemIcon></StyledListItemIcon>
                            <ListItemText>
                              <StyledDayOfWeek>
                                {dayFormatterUserInterface(day)}:
                              </StyledDayOfWeek>
                              {openingTime?.times?.[0]?.open}-
                              {openingTime?.times?.[0]?.close}
                            </ListItemText>
                          </ListItem>
                        ))
                      })}
                    </StyledList>
                  </Collapse>
                </StyledList>
              </FocusGrid>
            </Box>
          )}
          <Box clone order={{ xs: 2, md: 1 }}>
            <FocusGrid item xs={12} md={renderWithOpeningtimes ? 4 : 6}>
              <StyledA href={`https://maps.google.com/?q=${address}`}>
                <StyledClickableList>
                  <ListItem>
                    <StyledListItemIcon>
                      <RoomIcon />
                    </StyledListItemIcon>
                    <ListItemText>
                      {brand}
                      <br />
                      {street}
                      <br />
                      {postalcode} {city}
                    </ListItemText>
                  </ListItem>
                </StyledClickableList>
              </StyledA>

              {geolocation.latitude && geolocation.longitude && (
                <StyledList>
                  <ListItem>
                    <StyledListItemIcon>
                      <DriveEtaIcon />
                    </StyledListItemIcon>
                    <ListItemText
                      primary={`${distance(coords, {
                        lat: geolocation.latitude,
                        lng: geolocation.longitude,
                      })} km`}
                    />
                  </ListItem>
                </StyledList>
              )}
            </FocusGrid>
          </Box>
          <Box clone order={{ xs: 3, md: 3 }}>
            <FocusGrid item xs={12} md={renderWithOpeningtimes ? 4 : 6}>
              <StyledTypographyPriceHeadline align="center" variant="h2">
                Aktuelle Preise
              </StyledTypographyPriceHeadline>

              <StyledTableContainerPrices>
                <Table size="small">
                  <TableHead>
                    <TableRow>
                      <StyledTableHeaderCell align="left">
                        Kraftstoff
                      </StyledTableHeaderCell>
                      <StyledTableHeaderCell align="center">
                        pro Liter
                      </StyledTableHeaderCell>
                      <StyledTableHeaderCell align="right">
                        Preis-Entwicklung
                      </StyledTableHeaderCell>
                    </TableRow>
                  </TableHead>
                  <StyledTableBody>
                    {fuels.map(row => (
                      <TableRow key={row.name}>
                        <StyledTableBodyCell
                          component="th"
                          scope="row"
                          align="left"
                        >
                          {row.name}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell align="center">
                          {currencyFormatter(row.price)}
                        </StyledTableBodyCell>
                        <StyledTableBodyCell align="right">
                          {row?.lastChange?.amount < 0 && (
                            <>
                              <TrendingDownIcon />
                              &nbsp; Aktuell sinkend
                            </>
                          )}
                          {row?.lastChange?.amount > 0 && (
                            <>
                              <TrendingUpIcon />
                              &nbsp; Aktuell steigend
                            </>
                          )}
                        </StyledTableBodyCell>
                      </TableRow>
                    ))}
                  </StyledTableBody>
                </Table>
              </StyledTableContainerPrices>
            </FocusGrid>
          </Box>
        </Grid>

        <Grid container spacing={0} justify="flex-start">
          {renderWithOpeningtimes && (
            <Box
              clone
              display={{
                xs: "none",
                sm: "none",
                md: "flex",
                lg: "flex",
                xl: "flex",
              }}
            >
              <NeutralGrid item xs={12} md={renderWithOpeningtimes ? 4 : 6}>
                &nbsp;
              </NeutralGrid>
            </Box>
          )}
          <Box
            clone
            display={{
              xs: "none",
              sm: "none",
              md: "flex",
              lg: "flex",
              xl: "flex",
            }}
          >
            <NeutralGrid item xs={12} md={renderWithOpeningtimes ? 4 : 6}>
              &nbsp;
            </NeutralGrid>
          </Box>
          <Box clone order={{ xs: 3, md: 3 }}>
            <NeutralGrid item xs={12} md={renderWithOpeningtimes ? 4 : 6}>
              <StyledTypographyLastUpdated align="right" variant="body2">
                Aktualisiert:&nbsp;
                {dateTimeFormatter(fuels?.[0]?.lastChange?.timestamp)} Uhr
              </StyledTypographyLastUpdated>
            </NeutralGrid>
          </Box>
        </Grid>
        <script type="application/ld+json">
          {JSON.stringify([
            {
              ...env.structuredDataTemplateGasStation,
              url,
              "@id": url,
              brand,
              address: {
                "@type": "PostalAddress",
                streetAddress: street,
                postalCode: postalcode,
                addressLocality: city,
                addressRegion: county,
                addressCountry: "DE",
              },
              geo: {
                "@type": "GeoCoordinates",
                latitude: geolocation.latitude,
                longitude: geolocation.longitude,
              },
              openingHoursSpecification: renderWithOpeningtimes
                ? openingtimes
                    .map(openingTime =>
                      openingTime.days.map(day => ({
                        "@type": "OpeningHoursSpecification",
                        dayOfWeek: dayFormatterStructuredData(day),
                        opens: openingTime?.times?.[0]?.open,
                        closes: openingTime?.times?.[0]?.close,
                      }))
                    )
                    .flat()
                : [],
            },
            ...fuels.map(fuel => ({
              ...env.structuredDataTemplateProduct,
              name: fuel.name,
              description: fuel.name,
              brand,
              offers: {
                "@type": "Offer",
                price: fuel.price,
                priceCurrency: "EUR",
                url,
                availability: "http://schema.org/InStock",
              },
            })),
          ])}
        </script>

        <Spacer />
        <StyledTypographyH1 variant="h2" align="center">
          Tankstellen nach Bundesland
        </StyledTypographyH1>

        <Counties current={county} counties={counties} />
        <Spacer />

        <StyledTypographyH1 variant="h2" align="center">
          Tankstellen nach Stadt in {county}
        </StyledTypographyH1>
        <Cities
          county={county}
          current={city}
          counties={counties}
          cities={cities}
        />
        <AdsenseAd>
          <ins
            className="adsbygoogle"
            style={{ display: "block" }}
            data-ad-client="ca-pub-9201149966528047"
            data-ad-slot="8669834842"
            data-adtest={process.env.NODE_ENV === "development" ? "on" : "off"}
            data-ad-format="auto"
            data-full-width-responsive="true"
          ></ins>
        </AdsenseAd>
      </Main>
      <Footer />
    </Layout>
  )
}
